import Component from 'Component';
import $ from 'jquery';
import utils from 'utilities';

class PlotStickyNav {
    constructor($element) {

        if (!$element || $element.length === 0) {
            throw new Error('Element not found or not passed to the constructor.');
        }

        this.$element = $element;

        let infoBar = $('.sticky-infomation-bar');

        if (infoBar.length > 0 && infoBar.children().length > 0) {
            this.$element.addClass('info-bar-present');
        }

        this.attachSmoothScrollListener();

       this.initSlickCarousels();

        const plotHeaderButtons = $('.plot-header .buttons');
        const mobileStickyNav = $('.c-development-sticky-cta')

        if(plotHeaderButtons.length > 0) {
            let lastKnownScrollPosition = 0;
            let ticking = false;
            let topOfButtons = plotHeaderButtons.offset().top;
            let heightOfButtons = plotHeaderButtons.outerHeight();

            if($(window).scrollTop() < (topOfButtons + heightOfButtons) && $('html').width() <= 1023) {
                mobileStickyNav.hide();
            }


            document.addEventListener("scroll", (event) => {
                lastKnownScrollPosition = window.scrollY;

                if (!ticking) {
                  window.requestAnimationFrame(() => {
                    if($(window).scrollTop() > (topOfButtons + heightOfButtons) || $('html').width() >= 1024) {
                        mobileStickyNav.show();
                    }
                    else {
                        mobileStickyNav.hide();
                    }
                    ticking = false;
                  });
              
                  ticking = true;
                }            
            });
        }
    }

    handleAnchorClick(e) {
        e.preventDefault();
        const link = e.delegateTarget;
        const anchorTargetId = link.getAttribute('href');
        const anchorTarget = $(anchorTargetId);

        utils.scrollToPosition(anchorTarget, document.querySelector('.c-header'));
    }

    attachSmoothScrollListener() {
        this.$element.find('.c-secondary-nav__anchor-link[href^="#"]').on('click', (e) => this.handleAnchorClick(e));
    }

    detachSmoothScrollListener() {
        this.$element.find('.c-secondary-nav__anchor-link[href^="#"]').off('click', (e) => this.handleAnchorClick(e));
    }
    

    initSlickCarousels() {
      $(".plot-sticky-nav__carousel").not('.slick-initialized').slick({
        infinite: false,
        variableWidth: true,
        arrows: true,
        touchMove: true,
        mobileFirst: true,
          responsive: [
            {
               breakpoint: 480,
               settings: "unslick"
            }
         ]
      });
  }
}

export default PlotStickyNav;
