﻿import $ from "jquery";
import Header from 'Header';
import HeroBanner from 'HeroBanner';
import HeroBannerStatic from './HeroBanner/hero-banner-static';
import FullWidthContent from 'FullWidthContent';
import FullWidthMediaCopyBlock from 'FullWidthMediaCopyBlock';
import PromoItem from 'PromoItem';
import TwoColumnCarousel from 'TwoColumnCarousel';
import Footer from 'Footer';
import Notifications from 'Notifications';
import HeroVideo from 'HeroVideo';
import ThreeQuarterCarousel from 'ThreeQuarterCarousel';
import OverviewContent from 'OverviewContent';
import SidebarComponent from 'Sidebar';
import SecondaryNav from 'SecondaryNav';
import VideoCarousel from 'VideoCarousel';
import Specification from 'Specification';
import TourCarousel from 'TourCarousel';
import SitePlan from 'SitePlan';
import ExternalSitePlan from 'ExternalSitePlan';
import DevelopmentHomes from 'DevelopmentHomes';
import PlotShowcase from 'PlotShowcase';
import DevelopmentListing from 'DevelopmentListing';
import LocalArea from 'LocalArea';
import FloorPlans from 'FloorPlans';
import AdditionalInformation from 'AdditionalInformation';
import PlotSitePlan from 'PlotSitePlan';
import ScrollTracked from 'ScrollTracked';
import ArrangeAppointment from 'ArrangeAppointment';
import DownloadBrochure from 'DownloadBrochure';
import EnquiryForm from 'EnquiryForm';
import TabsNavigation from 'TabsNavigation';
import SecondaryHeroBanner from 'SecondaryHeroBanner';
import ImageTextBlock from 'ImageTextBlock';
import CtaImageBlock from 'CtaImageBlock';
import TestimonialCarousel from 'TestimonialCarousel';
import MediaBlock from 'MediaBlock';
import AccordionCopyBlock from 'AccordionCopyBlock';
import VacanciesListing from 'VacanciesListing'; 
import VacanciesSearch from 'VacanciesSearch';
import DepartmentsListing from 'DepartmentsListing'; 
import AnchorNav from 'AnchorNav';
import SubNav from 'SubNav';
import VacanciesSidebar from 'VacanciesSidebar';
import ModalFloorplan from 'ModalFloorplan';
import Community from './Developments/Community/community';
import ProgressBar from './Developments/DevelopmentForm/Components/progress-bar';
import DevelopmentCardBlock from './Developments/DevelopmentForm/Components/development-card-block';
import DevelopmentForm from './Developments/DevelopmentForm/development-form';
import LocationLink from './LocationLink/location-link';
import GoalFiring from '../../../../src/Foundation/SitecoreExtensions/code/Scripts/GoalFiring/goal-firing';
import FeatureBlock from 'FeatureBlock';
import OfferCarousel from 'OfferCarousel';
import PlotHeaderCarousel from './Plots/PlotHeader/plot-header-carousel'
import PlotStickyNav from './Plots/PlotStickyNav/plot-sticky-nav'
import LocationsReadMore from './LocationsReadMore/LocationsReadMore'
import SocialMedia from './SocialMedia/social-media'
import { throttle } from 'throttle-debounce'

class App {

    Sidebar;
    SecondaryNav;
    Notifications;
    Header;
    DevelopmentHomes;
    PlotShowcase;
    SitePlan;
    PlotSitePlan;
    LocalArea;
    components;
    externalScripts = {};
    youTubeAPIReadyQueue = [];

    init() {
        this.components =  $('[data-component]').map(this.mapComponentToClass.bind(this));

        new LocationLink().initialiseGtmListener();
        new GoalFiring().initialiseGoalFiringListener();

        document.body.addEventListener("mousedown", () => {
            document.body.classList.add("using-mouse");
        });
        document.body.addEventListener("keydown", ({ key }) => { 
            if (key === "Tab") { 
                document.body.classList.remove("using-mouse"); 
            } 
        });
        this.initReadMoreComponents();
    }

    mapComponentToClass(_i, component){
        const $component = $(component), type = $component.data('component').toLowerCase();

        switch(type){
            case "header":
                if(!this.Header)
                    this.Header = new Header($component);
                return this.Header;
            case "hero-banner":
                return new HeroBanner($component);
            case "hero-banner-static":
                return new HeroBannerStatic($component);
            case "full-width-content":
                return new FullWidthContent($component);
            case "full-width-media-copy-block":
                return new FullWidthMediaCopyBlock($component);
            case "promo-item":
                return new PromoItem($component);
            case "two-column-carousel":
                return new TwoColumnCarousel($component);
            case "footer":                
                return new Footer($component);
            case "notifications":
                if(!this.Notifications)
                    this.Notifications = new Notifications($component);
                return this.Notifications;
            case "hero-video":
                return new HeroVideo($component);
            case "three-quarter-carousel":
                return new ThreeQuarterCarousel($component);
            case "overview-content":
                return new OverviewContent($component);
            case "sidebar":
                if(!this.Sidebar)
                   this.Sidebar = new SidebarComponent($component, $component.data('collapsable'));
                return this.Sidebar;
            case "secondary-nav":
                if(!this.SecondaryNav)
                    this.SecondaryNav = new SecondaryNav($component);
                return this.SecondaryNav;
            case "video-carousel":
                return new VideoCarousel($component);
            case "accordions":
                return new Accordions($component);
            case "specification":
                return new Specification($component);
            case "tour-carousel":
                return new TourCarousel($component);
            case "site-plan":
                if(!this.SitePlan)
                    this.SitePlan = new SitePlan($component);
                return this.SitePlan;
            case "external-site-plan":
                if(!this.SitePlan)
                    this.SitePlan = new ExternalSitePlan($component);
                return this.SitePlan;
            case "plot-site-plan":
                if (!this.PlotSitePlan)
                    this.PlotSitePlan = new PlotSitePlan($component);
                return this.PlotSitePlan;
            case "development-homes":
                if(!this.DevelopmentHomes)
                    this.DevelopmentHomes = new DevelopmentHomes($component);
                return this.DevelopmentHomes;
            case "plot-showcase":
                if(!this.PlotShowcase)
                    this.PlotShowcase = new PlotShowcase($component);
                return this.PlotShowcase;
            case "local-area":
                if (!this.LocalArea) {
                    this.LocalArea = new LocalArea($component[0]);
                }
                return;
            case "floorplans":
                return new FloorPlans($component);
            case "feature-block":
                return new FeatureBlock($component);
            case "additional-information":
                return new AdditionalInformation($component);
            case "scroll-tracked":
                return new ScrollTracked($component);
            case "arrange-appointment":
                return new ArrangeAppointment($component);
            case "download-brochure":
                return new DownloadBrochure($component);
            case "enquiry-form":
                return new EnquiryForm($component);
            case "development-listing":
                return new DevelopmentListing($component);
            case "tabs-navigation":
                return new TabsNavigation($component);
            case "secondary-hero-banner":
                return new SecondaryHeroBanner($component);
            case "image-text-block":
                return new ImageTextBlock($component);
            case "cta-image-block":
                return new CtaImageBlock($component);
            case "testimonial-carousel":
                return new TestimonialCarousel($component);
            case "media-block":
                return new MediaBlock($component);
            case "accordion-copy-block":
                return new AccordionCopyBlock($component);
            case "vacancies-listing":
                if (!this.VacanciesListing)
                    this.VacanciesListing = new VacanciesListing($component);
                return this.VacanciesListing;
            case "vacancies-search":
                if (!this.VacanciesSearch)
                    this.VacanciesSearch = new VacanciesSearch($component);
                return this.VacanciesSearch;
            case "departments-listing":
                if (!this.DepartmentsListing)
                    this.DepartmentsListing = new DepartmentsListing($component);
                return this.DepartmentsListing;
            case "anchor-nav":
                if (!this.AnchorNav) {
                    this.AnchorNav = new AnchorNav($component);
                }
                return this.AnchorNav;
            case "sub-nav":
                if (!this.SubNav) {
                    this.SubNav = new SubNav($component);
                }
                return this.SubNav;
            case "vacancies-sidebar":
                if (!this.VacanciesSidebar)
                    this.VacanciesSidebar = new VacanciesSidebar($component, $component.data('collapsable'));
                return this.VacanciesSidebar;
            case "modal-floorplan":
                return new ModalFloorplan($component);
            case "community":
                return new Community($component);
            case "development-form":
                return new DevelopmentForm($component);
            case "progress-bar":
                return new ProgressBar($component);
            case "development-card-block":
                return new DevelopmentCardBlock($component);
            case "offer-carousel":
                return new OfferCarousel($component);
            case "plot-header":
                return new PlotHeaderCarousel($component);
            case "plot-sticky-nav":
                return new PlotStickyNav($component);
            case "social-media":
                return new SocialMedia($component);
            default:
                console.log(`"${type}" is a unrecognised component type`)
        }
        
    }
    
    updateSidebarMinHeight(height){
        if(this.Sidebar){
            this.Sidebar.setHeight(height);
        } 

        if (this.VacanciesSidebar) {
            this.VacanciesSidebar.setHeight(height);
        } 

        return this;
    }

    applyVacanciesFilters(keyword, department, location) {
        if (this.VacanciesListing) {
            this.VacanciesListing.applyFilters(keyword, department, location);
        }

        return this;
    }

    fadeInSideBar(){
        if(this.Sidebar){
            this.Sidebar.fadeIn();
        } 

        return this;
    }

    getHeaderHeight(){
        if(this.Header)
            return this.Header.height;
    }

    hideHeader(){
        if(this.Header){
            this.Header.hide();
        }

        return this;
    }

    showHeader(){
        if(this.Header){
            this.Header.show();
        }

        return this;
    }

    setVacanciesSidebarState(state) {
        const { VacanciesSidebar } = this;

        if (VacanciesSidebar) {
            VacanciesSidebar.setState(state);
        }
    }


    setSidebarState(state){
        const { Sidebar } = this;

        if(Sidebar) {
            Sidebar.setState(state);
        }

        this.setVacanciesSidebarState(state);
    }

    setAnchorNavState(state) {
        const { AnchorNav } = this;

        if (this.AnchorNav) {
            AnchorNav.setState(state);
        }
    }

    setSecondaryNavState(state) {
        const { SecondaryNav } = this;

        if (SecondaryNav) {
            SecondaryNav.setState(state);
        }

       this.setAnchorNavState(state);
    }

    addExternalScript(name, url, onload) {

        if(!(name in this.externalScripts)){

            const tag = $('<script></script>');

            const firstScriptTag = $('script')[0];
            
            tag.insertBefore(firstScriptTag);    
                        
            if(onload) {
                tag.on('load', onload);
            }

            tag.attr('src', url);  

            this.externalScripts[name] = url;
            
        } else {
            onload && onload();
        }
    }

    
    setupYouTubeAPI(callback) {
        this.addExternalScript('youtube', "https://www.youtube.com/iframe_api");

        this.addYouTubeAPIReadyCallback();

        if(callback) this.addToYouTubeAPIReadyQueue(callback);
    } 

    setupGoogleMapAPI(onscriptload) {

        const apiKey = 'AIzaSyDBRj0yyBtQ50o8geSjePHVbKr_7zXsrrk';

        this.addExternalScript('google-map', `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=en`, () => onscriptload(apiKey));

    } 

    addYouTubeAPIReadyCallback() {        
        if(!this.youtubeApiInitialized) {
            window.onYouTubeIframeAPIReady = () => {
                this.youTubeAPIReadyQueue.forEach((callback) => callback());
            }
            this.youtubeApiInitialized = true;
        }
    }

    addToYouTubeAPIReadyQueue(callback){
        this.youTubeAPIReadyQueue.push(callback);
    }

    setUpVimeoAPI(onscriptload) {
        this.addExternalScript('vimeo', 'https://player.vimeo.com/api/player.js', onscriptload);
    }

    showNotifications() {
        if(this.Notifications)
            this.Notifications.show();
    }

    hideNotifications() {
        if(this.Notifications)
            this.Notifications.hide();
    }

    updateHomesFilterSelections(minPrice, maxPrice, minBedrooms, maxBedrooms){
        if(this.DevelopmentHomes)
            this.DevelopmentHomes.setFilterValues(minPrice, maxPrice, minBedrooms, maxBedrooms)
    }

    updateMapFilterSelections(minPrice, maxPrice, minBedrooms, maxBedrooms){
        if(this.SitePlan)
            this.SitePlan.setFilterValues(minPrice, maxPrice, minBedrooms, maxBedrooms)
    }

    initReadMoreComponents() {
        const readMoreElements = document.querySelectorAll('.c-local-area-copy');
        const readMoreInstances = [];
    
        readMoreElements.forEach((element) => {
            const instance = new LocationsReadMore(element);
            readMoreInstances.push(instance);
        });
    
        window.addEventListener('resize', throttle(300, () => {
            readMoreInstances.forEach((instance) => {
                instance.initializeState(); 
            });
        }));
    }

}

export default new App();
