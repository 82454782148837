import icons from './helpers/icons.js';
import App from 'App';

class LocalArea {

  constructor(containerElement) {
    if (!containerElement) {
      return;
    }

    this.mapContent = '.c-locations-list #c-locations-list__map';
    this.currentMarkers = [];
    this.map = null;
    this.developmentLocation = null;
    this.isMobile = window.innerWidth < 1024;
    this.containerElement = containerElement;
    this.icons = icons; 
    this.developmentID = this.containerElement.getAttribute('data-development-id');
    this.bounds = null;
  }

  async fetchAmenitiesData() {
    const isDevelopment = false;
    if (isDevelopment) {
      const mapElement = this.containerElement.querySelector(this.mapContent);
      const apiAddress = mapElement ? mapElement.getAttribute('data-places').replace('{id}', this.developmentID) : null;
      const response = await fetch('http://localhost:3002/fetchAmenities');
      return this.validateResponse(response);
    } else {
      const mapElement = this.containerElement.querySelector(this.mapContent);
      const apiAddress = mapElement ? mapElement.getAttribute('data-places').replace('{id}', this.developmentID) : null;
      if (apiAddress) {
        const response = await fetch(apiAddress);
        return this.validateResponse(response);
      }
    }
  }

  async validateResponse(response) {
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
  }

  async initialiseApplication() {
    try {
      const amenitiesData = await this.fetchAmenitiesData();
      if (!amenitiesData) {
        throw new Error("Amenities data could not be fetched.");
      }
      this.setupAppWithAmenitiesData(amenitiesData);
    } catch (error) {
      console.error("An error occurred in initialiseApplication:", error);
    }
  }

  handleAmenityListScroll = () => {
    const amenityList = this.containerElement.querySelector('.c-locations-list__amenity-list--inner');
    const gradientDiv = this.containerElement.querySelector('.c-locations-list__gradient');

    amenityList.addEventListener('scroll', () => {
      const isAtBottom = amenityList.scrollHeight - amenityList.scrollTop === amenityList.clientHeight;
      gradientDiv.style.display = isAtBottom ? 'none' : 'block';
    });
  }

  setupAppWithAmenitiesData(amenitiesData) {
    App.setupGoogleMapAPI(() => {
      this.google = google;
      this.initialiseMapWithData(amenitiesData);
      this.createAndPopulateAmenityButtons(amenitiesData.Amenities);
      this.populateMapMarkers(amenitiesData.Amenities[0].AmenityDetails, this.icons);
      this.handleDynamicMap();
    });
  }

  initialiseMapWithData(amenitiesData) {
    try {
      // Parse latitude and longitude and set developmentLocation
      this.developmentLocation = {
        lat: parseFloat(amenitiesData.DevelopmentDetails.Latitude),
        lng: parseFloat(amenitiesData.DevelopmentDetails.Longitude)
      };

      // Determine the element ID based on whether the device is mobile
      const elementId = this.isMobile ? "c-locations-list__map-mobile" : "c-locations-list__map";

      // Corrected the recursive call
      this.initialiseMapWithCustomControls(elementId);

      // The rest of the code remains the same
      this.createAndPopulateAmenityButtons(amenitiesData.Amenities);
      this.handleDynamicMap();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  createCustomZoomControl(controlDiv, icons) {
    const zoomControlButton = document.createElement('div');
    zoomControlButton.setAttribute('aria-label', 'Zoom control');
    zoomControlButton.setAttribute('role', 'button');
    const decodedZoomSvg = atob(icons.zoomControls);

    zoomControlButton.innerHTML = decodedZoomSvg;
    controlDiv.appendChild(zoomControlButton);

    zoomControlButton.addEventListener('click', (event) => {
      const rect = zoomControlButton.getBoundingClientRect();
      const y = event.clientY - rect.top;

      if (y < rect.height / 2) {
        this.map.setZoom(Math.ceil(this.map.getZoom()) + 1);
      } 
      else {
        this.map.setZoom(Math.floor(this.map.getZoom()) - 1);
      }
    });
  }

  createCustomFullscreenControl(controlDiv, icons) {
    const fullscreenButton = document.createElement('div');
    const decodedFullscreenSvg = atob(icons.fullscreenSvg);

    fullscreenButton.innerHTML = decodedFullscreenSvg;

    controlDiv.appendChild(fullscreenButton);

    fullscreenButton.addEventListener('click', () => {
      // Check if any element is currently in fullscreen mode
      if (!document.fullscreenElement) {
        this.map.getDiv().requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    });
  }

  initialiseMapWithCustomControls = elementId => {
    const customOptions = {
      zoom: 14,
      center: this.developmentLocation,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false
    };

    this.map = new google.maps.Map(this.containerElement.querySelector(`#${elementId}`), customOptions);
    this.bounds = new google.maps.LatLngBounds();

    const zoomControlDiv = document.createElement("div");
    zoomControlDiv.style.marginBottom = '-5px';
    zoomControlDiv.style.marginRight = '5px';
    this.createCustomZoomControl(zoomControlDiv, icons);

    if (this.isMobile) {
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomControlDiv);
    } else {
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);
    }
  
    const fullscreenControlDiv = document.createElement("div");
    fullscreenControlDiv.style.marginTop = '5px';
    fullscreenControlDiv.style.marginRight = '5px';
    this.createCustomFullscreenControl(fullscreenControlDiv, icons);

    if (!this.isMobile) {
      this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(fullscreenControlDiv);
    }

    this.currentMarkers.forEach(marker => {
      marker.setMap(this.map); // Use 'this.map' to access the map object
      let latLng = new google.maps.LatLng(marker[1], marker[2]);     
      this.bounds.extend(latLng);
    });

    this.map.fitBounds(this.bounds);
    this.map.setZoom(this.map.getZoom() - 1); 
  };

  handleDynamicMap = () => {
    window.addEventListener('resize', this.reInitialiseMap);
  };

  reInitialiseMap = async () => { 
    const newIsMobile = window.innerWidth < 1024;

    if (newIsMobile !== this.isMobile) {
      this.isMobile = newIsMobile;
      const elementId = this.isMobile ? "c-locations-list__map-mobile" : "c-locations-list__map";
      this.initialiseMapWithCustomControls(elementId);
      LocalArea.start();
    }
  };

  createAmenityButtonElement = (type, index) => {
    const button = document.createElement("button");
    button.type = "button";
    button.className = "c-locations-list__button";
    button.setAttribute('role', 'tab'); // Set role as tab for accesibility
    button.setAttribute('aria-label', type.AmenityTypeName);

    const isActive = button.classList.contains('reveal');
    button.setAttribute('aria-selected', isActive.toString());

    const iconDiv = document.createElement("div");
    iconDiv.className = `c-locations-list__button-icon ${type.AmenityCssClass.toLowerCase()}`;
    button.appendChild(iconDiv);

    const span = document.createElement("span");
    span.textContent = type.AmenityTypeName;
    button.appendChild(span);

    this.attachClickEventToAmenityButton(button, type);

    return button;
  }

  attachClickEventToAmenityButton = (button, type) => {
    button.addEventListener('click', () => {

      const listContainer = this.containerElement.querySelector('.c-locations-list__amenity-list--inner');
      if (listContainer) {
        listContainer.scrollLeft = 0
      }

      requestAnimationFrame(() => {
        // First clear all active amenities
        this.clearAllActiveAmenities();

        // Populate the list and markers for the new category
        this.populateAmenityDetailsList(type);
        this.populateMapMarkers(type.AmenityDetails, this.icons);

        // Deactivate all buttons and then activate the clicked one
        const allButtons = document.querySelectorAll('.c-locations-list__button');
        allButtons.forEach(btn => {
          btn.classList.remove('reveal');
          btn.setAttribute('aria-selected', 'false');
        });

        button.classList.add('reveal');
        button.setAttribute('aria-selected', 'true');
      })
    });
  }  

  createAndPopulateAmenityButtons(amenityTypes) {
    const container = this.containerElement.querySelector("#c-locations-list__amenity-buttons-container");
    container.innerHTML = '';

    amenityTypes.forEach((type, index) => {
      const button = this.createAmenityButtonElement(type, index);
      if (index === 0) {
        button.classList.add('reveal'); // Set the first button as active
        button.setAttribute('aria-selected', 'true'); // Set aria-selected to true for the active button
      }
      this.attachClickEventToAmenityButton(button, type);
      container.appendChild(button);
    });

    if (amenityTypes.length > 0) {
      this.prePopulateAmenities(amenityTypes);
    }
  }

  prePopulateAmenities(amenityTypes) {
    if (amenityTypes.length > 0) {
      this.populateAmenityDetailsList(amenityTypes[0]);
      this.populateMapMarkers(amenityTypes[0].AmenityDetails, icons);
    }
  }

  setupEventListeners() {
    window.addEventListener('resize', this.reInitialiseMap);
    document.addEventListener('click', this.clearIfClickedOutside);
  }

  setDisplayState(element, state) {
    if (element) {
      element.style.display = state;
    }
  }

  toggleClasses(element, classesToAdd, classesToRemove) {
    if (element) {
      classesToAdd.forEach(cls => element.classList.add(cls));
      classesToRemove.forEach(cls => element.classList.remove(cls));
    }
  }

  resetMarkerIcons(markers, iconUrl) {
    markers.forEach(marker => {
      marker.setIcon({ url: iconUrl });
    });
  }

  updateMapInfoBoxWithAmenityDetails(amenity) {
    const mapInfoBox = this.containerElement.querySelector('.c-locations-list__map-information');

    const content = `
      <div class="c-locations-list__title-address">
        <span class="c-locations-list__amenity-title">${amenity.Name}</span>
        <span class="c-locations-list__amenity-address">${amenity.AddressLine1}, ${amenity.Postcode}</span>
      </div>
      <div class="c-locations-list__directions-link">
        <a class="c-link" href="https://www.google.com/maps/dir/?api=1&destination=${amenity.Latitude},${amenity.Longitude}" target="_blank">Get directions</a>
      </div>
    `;

    mapInfoBox.innerHTML = content;
  }

  toggleSelectedAmenity = (amenityElement, index, currentMarkers, mapInfoBox, allAmenities) => {
    const deactivateAmenity = () => {
      this.toggleClasses(amenityElement, ['fade'], ['active']);
      this.setDisplayState(mapInfoBox, 'none');
      allAmenities.forEach(el => el.classList.remove('fade'));

      // Reset icons, z-index, and label text for all markers
      this.resetMarkersZIndex();
    };

      const activateAmenity = () => {
          allAmenities.forEach(el => {
              this.toggleClasses(el, ['fade'], ['active']);
          });
          this.toggleClasses(amenityElement, ['active'], ['fade']);
          // Reset icons for all markers
          currentMarkers.forEach((marker, idx) => {
              marker.setIcon({ url: `data:image/svg+xml;base64,${this.icons.navyPin}` });
              marker.setZIndex(1);
          });
          // Update icon and z-index for the active marker
          const activeMarker = currentMarkers[index];
          activeMarker.setIcon({ url: `data:image/svg+xml;base64,${this.icons.purplePin}` });
          activeMarker.setZIndex(10);
          this.map.panTo(activeMarker.getPosition());
          this.updateMapInfoBoxWithAmenityDetails(allAmenities[index].amenityData);
          this.setDisplayState(mapInfoBox, 'flex');
      };

      if (amenityElement.classList.contains('active')) {
        deactivateAmenity();
      } else {
        activateAmenity();
      }
  };

  clearAllActiveAmenities = () => {
    const allAmenities = document.querySelectorAll('.c-locations-list__amenity-info');
    allAmenities.forEach(el => {
      el.classList.remove('active', 'fade');
    });
    const mapInfoBox = this.containerElement.querySelector('.c-locations-list__map-information');
    this.setDisplayState(mapInfoBox, 'none');
    this.resetMarkersZIndex();
  };

  resetMarkersZIndex() {
    this.currentMarkers.forEach((marker, index) => {
      marker.setIcon({ url: `data:image/svg+xml;base64,${this.icons.navyPin}` });
      marker.setZIndex(index + 1);
      marker.setLabel({
        text: `${index + 1}`,
        color: "white"
      });
    });
  }

  createAmenityInfoDiv = (amenity, index) => {
    const amenityInfo = document.createElement('div');
    amenityInfo.className = 'c-locations-list__amenity-info';
    amenityInfo.amenityData = amenity;

    const pin = document.createElement('div');
    pin.className = 'c-locations-list__pin-number';
    const span = document.createElement('span');
    span.textContent = `${index + 1}`;
    pin.appendChild(span);
    amenityInfo.appendChild(pin);

    const amenityDetailsWrapper = document.createElement('div');
    amenityDetailsWrapper.className = 'c-locations-list__amenity-details';

    const titleAndAddressWrapper = document.createElement('div');
    titleAndAddressWrapper.className = 'c-locations-list__title-address-wrapper';

    const title = document.createElement('span');
    title.className = 'c-locations-list__amenity-title';
    title.textContent = amenity.Name;
    titleAndAddressWrapper.appendChild(title);

    const addressAndProximity = document.createElement('div');
    addressAndProximity.className = 'c-locations-list__address-proximity';

    const address = document.createElement('span');
    address.className = 'c-locations-list__amenity-address';
    address.textContent = `${amenity.AddressLine1}, ${amenity.Postcode}`;
    addressAndProximity.appendChild(address);

    const proximity = document.createElement('span');
    proximity.className = 'amenity-proximity';
    proximity.textContent = amenity.Proximity;
    addressAndProximity.appendChild(proximity);

    titleAndAddressWrapper.appendChild(addressAndProximity);
    amenityDetailsWrapper.appendChild(titleAndAddressWrapper);
    amenityInfo.appendChild(amenityDetailsWrapper);

    return amenityInfo;
  };

  handleAmenityClick = (amenityInfo, index, listContainer, mapInfoBox) => {
    amenityInfo.addEventListener('click', () => {
      this.toggleSelectedAmenity(amenityInfo, index, this.currentMarkers, mapInfoBox, listContainer.querySelectorAll('.c-locations-list__amenity-info'));
    });
  };

  // Helper function to handle click events outside of amenities list (to deactivate state)
  attachClearOutsideEventListener = (listContainer, mapInfoBox) => {
    const clearIfClickedOutside = (event) => {
      const clickedElement = event.target;
      const allAmenities = listContainer.querySelectorAll('.c-locations-list__amenity-info');

      if (!clickedElement.closest('.c-locations-list__amenity-info') &&
          !clickedElement.closest('.c-locations-list__map-mobile') &&
          !clickedElement.closest('.c-locations-list__map')) {
        this.clearAllActiveAmenities(this.currentMarkers, mapInfoBox, allAmenities);
      }
    };

    document.addEventListener('click', clearIfClickedOutside);
  };

  populateAmenityDetailsList = (amenityType) => {

    const listContainer = this.containerElement.querySelector('.c-locations-list__amenity-list');
    listContainer.innerHTML = '';

    const innerContainer = document.createElement('div');
    innerContainer.className = 'c-locations-list__amenity-list--inner';
    listContainer.appendChild(innerContainer);

    const mapInfoBox = this.containerElement.querySelector('.c-locations-list__map-information');
    this.setDisplayState(mapInfoBox, 'none');

    amenityType.AmenityDetails.forEach((amenity, index) => {
      const amenityInfo = this.createAmenityInfoDiv(amenity, index);

      amenityInfo.tabIndex = 0;
      amenityInfo.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          this.handleActiveAmenity(amenity, index);
        }
      });

      this.handleAmenityClick(amenityInfo, index, innerContainer, mapInfoBox);
      innerContainer.appendChild(amenityInfo);
    });
    this.attachClearOutsideEventListener(innerContainer, mapInfoBox);

    if (!this.isMobile) {
      this.handleAmenityListScroll();
    }
  };

  handleActiveAmenity = (amenity, index) => {
    const allAmenities = document.querySelectorAll('.c-locations-list__amenity-info');
    const selectedAmenityElement = allAmenities[index];
    const mapInfoBox = this.containerElement.querySelector('.c-locations-list__map-information');

    // Clear all active amenities
    this.clearAllActiveAmenities();

    // Activate the selected amenity
    this.toggleClasses(selectedAmenityElement, ['active'], ['fade']);
    selectedAmenityElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    // Fade out other amenities
    allAmenities.forEach((el, idx) => {
      if (idx !== index) {
        this.toggleClasses(el, ['fade'], ['active']);
      }
    });

    // Update the map and info box
    this.updateMapInfoBoxWithAmenityDetails(amenity);
    this.map.panTo(this.currentMarkers[index].getPosition());
    this.currentMarkers[index].setIcon({ url: `data:image/svg+xml;base64,${icons.purplePin}` });
    mapInfoBox.style.display = 'flex';
  };

  toggleListAmenityFadeState = (activeIndex) => {
    const allAmenities = document.querySelectorAll('.c-locations-list__amenity-info');
    allAmenities.forEach((el, idx) => {
      if (idx !== activeIndex) {
        this.toggleClasses(el, ['fade'], ['active']);
      }
    });
  };

  populateMapMarkers = (amenityDetails, icons) => {
    this.currentMarkers.forEach(marker => marker.setMap(null));
    this.currentMarkers = [];

    let latSum = 0, lngSum = 0;

    this.bounds = new google.maps.LatLngBounds();

    amenityDetails.forEach((amenity, index) => {
      const lat = parseFloat(amenity.Latitude);
      const lng = parseFloat(amenity.Longitude);
      latSum += lat;
      lngSum += lng;
      const marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        title: amenity.Name,
        icon: {
          url: `data:image/svg+xml;base64,${icons.navyPin}`,
        },
        label: {
          text: `${index + 1}`,
          color: "white"
        },
        zIndex: index + 1
      });
      let latLng = new google.maps.LatLng(lat, lng);  
      this.bounds.extend(latLng);

      marker.addListener('click', () => {
        const amenityElement = document.querySelectorAll('.c-locations-list__amenity-info')[index];
        const isAlreadyActive = amenityElement.classList.contains('active');
        this.clearAllActiveAmenities();
      
        if (!isAlreadyActive) {
          this.handleActiveAmenity(amenity, index);
          this.bringMarkerToFront(marker);
        }
      });

      this.currentMarkers.push(marker);
    });

    this.map.fitBounds(this.bounds);
    this.map.setZoom(this.map.getZoom() - 1); 

    // Calculate the central point
    const centerLat = latSum / amenityDetails.length;
    const centerLng = lngSum / amenityDetails.length;

    // Set map center without changing the zoom level
    this.map.setCenter({ lat: centerLat, lng: centerLng });
    if (this.map.getZoom() > 14) {
      this.map.setZoom(14);
    }
  };

  bringMarkerToFront(clickedMarker) {
    clickedMarker.setZIndex(this.currentMarkers.length + 1);
    clickedMarker.setIcon({
      url: `data:image/svg+xml;base64,${this.icons.purplePin}`,
    });

    this.currentMarkers
      .filter(marker => marker !== clickedMarker)
      .sort((a, b) => {
        return b.getPosition().lat() - a.getPosition().lat();
      })
      .forEach((marker, idx) => {
        marker.setZIndex(idx + 1);
      });
  }

  toggleListAmenityActiveState = (index) => {
    const allAmenities = document.querySelectorAll('.c-locations-list__amenity-info');
    allAmenities.forEach((el, idx) => {
        if (idx === index) {
            el.classList.contains('active') ? el.classList.remove('active') : el.classList.add('active');
        } else {
            el.classList.remove('active');
        }
    });
  };

  static start() {
    const domLoaded = new Promise((resolve) => {
      document.addEventListener("DOMContentLoaded", () => {
        resolve();
      });
    });

    domLoaded.then(() => {
      const element = document.querySelector('.c-locations-list');
      if (element) {
        new LocalArea(element).initialiseApplication();
      }
    });
  }
}

LocalArea.start();

export default LocalArea;
